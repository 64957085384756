import React from "react";

import ProtectAdCard from "./ProtectAdCard";

const data = [
    {
        logo: "/img/mark1.png",
        title: "Blockchain App Development",
        description: "Develop end to end solutions with us for your requirements of Dapps, DEXs, Wallets and NFT Marketplaces. And support to maintain Blockchain projects for future enhancements.",
        url: "/contact",
        id: "development"
    },
    {
        logo: "/img/mark3.png",
        title: "Blockchain Node Spin-Ups",
        description: "We help Spin-Up nodes for any Blockchain network such as Bitcoin Lightnodes, Ethereum, Polygon, Substrate etc. and support Blockchain nodes to maintain with updates, patches.",
        url: "/contact",
        id: "nodes"
    },
    {
        logo: "/img/mark2.png",
        title: "Smart Contract Auditing Services",
        description: "Secure your Smart Contracts code by adopting recommended best practices. We provide smart contract auditing services and help to secure your Blockchain ecosystem.",
        url: "/",
        id: "audits"
    },    
]

export default function ProtectAd() {
    return (
        <div className="text-center relative background-with-blur-protect overflow-hidden pt-12">
            <h2 className="text-[48px] text-purple">Blockchain Services</h2>
            <p className="text-[23px] text-charcoal">Build, Secure and Maintain your projects with us</p>
            <div className="flex mt-36 mx-16 md:mx-[86px] flex-wrap lg:flex-nowrap">
                { data.map(item => 
                    <ProtectAdCard key={item.title} logo={item.logo} title={item.title} description={item.description} url={item.url} id={item.id}/>
                )}
            </div>
        </div>
    )
}