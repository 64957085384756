import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import BlockChain from './pages/BlockChain';
import ComingSoon from './pages/ComingSoon';
import Web3Services from './components/blockchain/Web3Services';
import AboutPage from './components/blockchain/AboutPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<BlockChain/>} />
          <Route path="/security" element={<ComingSoon/>} />
          <Route path="/comingsoon" element={<ComingSoon/>} />
          <Route path="/services" element={<Web3Services/>} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/security" element={<ComingSoon/>} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;