import React from "react";
import UnderConstruction from "../../pages/UnderConstruction";


export default function Web3Services() {
    return (
        <div className="bg-[lightgrey] text-white font-sans">
            <UnderConstruction />
        </div>
    )
}