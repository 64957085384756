import React from "react";

import { Link } from "react-router-dom";

export default function ProtectAdCard({logo, title, description, url, id}) {
    return (
        <div className="text-center card-background m-4 rounded-lg mt-20">
            <img src={logo} alt="logo" className="mx-auto mt-[-120px]"/>
            <h4 className="mx-20 my-12 mt-6 text-[23px] min-h-20">{title}</h4>
            <p className="mx-12 text-[19px]">{description}</p>
            <button className="my-12 connect-wallet-btn">
                <Link to={url} className="flex">
                    {id === 'audits' ? 'Available Soon..' : 'Contact us'}
                    <img src="/img/vector.svg" alt="arrow"/>
                </Link>
            </button>
        </div>
    )
}