import React from "react";

import NavBarLatest from "../components/NavBarLatest";
import Footer from "../components/Footer";

export default function UnderConstruction() {
    return (
        <div className="bg-[lightgrey] h-screen text-white relative background-with-blur font-sans">
            <NavBarLatest/>
            <div className="flex flex-col justify-center text-center">
                <h2 className="text-[48px] md:text-[91px] mt-8 md:mt-24 mb-4 font-[500] text-purple">Under Construction</h2>
                <p className="text-[19px] mb-3 text-charcoal">We are going to setup this area very Soon.</p>
                <p className="text-[19px] mb-3 text-charcoal">Stay Tuned.</p>
                <div className="mt-6">
                    <div className="w-[300px] md:w-[400px] mx-auto relative">
                        <input type="text" placeholder="Please enter your email address" className="pl-8 pr-12 py-4 rounded-full w-[300px] md:w-[400px] text-[#A9A9A9] bg-[#24263D] outline-none"/>
                        <button className="bg-[#7DA0FB] px-4 md:px-10 py-4 rounded-full absolute right-0 md:right-[-50px] focus:border-none focus:outline-none border-none outline-none active:bg-[#4645C2]">Notify Me</button>
                    </div>                    
                </div>
                <p className="text-charcoal mt-8">Notify me when the website is launched.</p>
            </div>
            <div className="flex justify-between">
                <img src="/img/decorate1.png" alt="decoration" className="mx-[15%] mt-8 absolute"/>
                <img src="/img/decorate2.png" alt="decoration" className="mx-[15%] mt-8 absolute right-0"/>
            </div>
            <Footer />
        </div>
    )
}