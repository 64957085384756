import React from "react";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ProductsMenu from "./ProductsMenu";

// const links = [
    // {
    //     title: "Home",
    //     url: "/"
    // },
    // {
    //     title: "Prodcts",
    //     url: "/products"
    // },
    // {
    //     title: "Blockchain Security",
    //     url: "/security"
    // },
    // {
    //     title: "Web3.0 Connectors",
    //     url: "/security"
    // },
    // {
    //     title: "Asset Tokenization",
    //     url: "/security"
    // },
    // {
    //     title: "Web3.0 Services",
    //     url: "/services"
    // },
    // {
    //     title: "About",
    //     url: "/about"
    // },
    // {
    //     title: "Contact",
    //     url: "/contact"
    // }
// ]

export default function NavBarLatest() {
    const { pathname } = useLocation();
    return (
        <div className="flex justify-between">
            <div className="flex mx-4 md:mx-[56px] my-[4px]">
                <img 
                    src="/img/gritty-blockways-high-resolution-logo-transparent.svg" 
                    alt="logo" 
                    className="mr-2"
                    width={180}/>
            </div>
            <ul className="hidden md:mx-[66px] lg:flex items-center text-charcoal font-semibold">
                <li key='/' className={`mx-5 hover:text-[#800080] cursor-pointer ${'/' === pathname && "text-[#800080]"}`}>
                    <Link to='/'>Home</Link>
                </li>
                <li><ProductsMenu /></li>
                <li key='/services' className={`mx-5 hover:text-[#800080] cursor-pointer ${'/services' === pathname && "text-[#800080]"}`}>
                    <Link to='/services'>Web3.0 Services</Link>
                </li>   
                <li key='/about' className={`mx-5 hover:text-[#800080] cursor-pointer ${'/about' === pathname && "text-[#800080]"}`}>
                    <Link to='/about'>About</Link>
                </li>                
            </ul>
        </div>
    )
}