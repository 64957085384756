import React, { useRef, useState } from 'react';
import emailjs, { send } from '@emailjs/browser';
import NavBarLatest from './NavBarLatest'
import Footer from './Footer'

export default function ContactPage() {
    const form = useRef();
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [senderEmail, setSenderEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [contactMessage, setContactMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_b5kuihh', 'template_wjw2smj', form.current, {
        publicKey: '6bzSc9wPCyCPDgOKd',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setFirstName('');
          setLastName('');
          setSenderEmail('');
          setPhoneNumber('');
          setContactMessage('');
          const thankYouMessage = document.querySelector('#thank-you-message');
          thankYouMessage.classList.add('show');
          setTimeout(() => thankYouMessage.classList.remove('show'), 4000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          const noThankYouMessage = document.querySelector('#no-thank-you-message');
          noThankYouMessage.classList.add('show');
        },
      );
  };

  return (
    <div className="bg-[lightgrey] text-white font-sans">        
        <NavBarLatest />
        <form ref={form} onSubmit={sendEmail} className="text-center px-12 pt-12 pb-2 mx-4 md:mx-[246px] charcoal-text">
            <div className="space-y-12">
                <div className="pb-6">
                <h2 className="text-base font-semibold leading-7 text-purple">Contact Us</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Send us your Enquiry and we will get back to you.</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            First name
                        </label>
                        <div className="mt-2">
                            <input
                            type="text"
                            // name="first-name"
                            name="from_name"
                            id="first-name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Last name
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="last_name"
                        id="last-name"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-2">
                    <label htmlFor="sender-email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                        id="sender-email"
                        name="reply-to"
                        type="email"
                        value={senderEmail}
                        onChange={e => setSenderEmail(e.target.value)}
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-2">
                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                        Phone Number
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        autoComplete="phone-number"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-2">
                    <label htmlFor="request-type" className="block text-sm font-medium leading-6 text-gray-900">
                        Request Type
                    </label>
                    <div className="mt-2">
                        <select
                        id="request-type"
                        name="contact-request-type"                        
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                        <option>Web3.0 Services</option>
                        <option>Products</option>
                        <option>General Enquiry</option>
                        </select>
                    </div>
                    </div>                
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="requestInfo" className="block text-sm font-medium leading-6 text-gray-900">
                            Request Details
                        </label>
                        <div className="mt-2">
                            <textarea
                            id="requestInfo"
                            name="message"
                            rows={3}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={''}
                            value={contactMessage}
                            onChange={e => setContactMessage(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                </div>            
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button> */}
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Submit
                </button>
            </div>
        </form>
        <div className='text-center text-charcoal pt-8'>
            <p id="thank-you-message">
                Thank you for contacting us. We will be in touch with you very soon.
            </p>
        </div>
        <div className='text-center text-charcoal pt-8'>
            <p id="no-thank-you-message">
                Request is not submitted. Please try again or get in touch through our social channels.
            </p>
        </div>
        <Footer />
    </div>
  )
}
