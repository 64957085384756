import React from "react";
import Intro from "../components/blockchain/Intro";
import ProtectAd from "../components/blockchain/ProtectAd";
import Web3Games from "../components/blockchain/Web3Games";
import GetStarted from "../components/blockchain/GetStarted";
import Footer from "../components/Footer";
import NavBarLatest from "../components/NavBarLatest";

export default function BlockChain() {
    const productsRef = React.useRef();
    const servicesRef = React.useRef();
    return (
        // <div className="bg-[#090B17] text-white font-sans">
        //     <NavBarWithAuth/>
        //     <Intro/>
        //     <ProtectAd/>
        //     <Web3Exp/>
        //     <Web3Games/>
        //     <UserNumber/>
        //     <GetStarted/>
        //     <Footer/>
        // </div>
        <div className="bg-[lightgrey] text-white font-sans">
            <NavBarLatest />
            <Intro productsRef={productsRef} servicesRef={servicesRef}/>
            <div ref={productsRef}>
                <GetStarted/>
                <Web3Games/>
            </div>                          
            <div ref={servicesRef}>
                <ProtectAd />
            </div>            
            {/* <Web3Exp/> */}            
            {/* <UserNumber/> */}            
            <Footer/>
        </div>
    )
}