import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="px-4 md:px-[90px] mt-[100px] footer">
            <div className="flex  justify-between flex-wrap">
                <div className="flex pt-8 items-start">
                    {/* <div className="flex">
                        <img 
                            src="/img/gritty-blockways-high-resolution-logo-transparent.svg" 
                            alt="logo" 
                            className="mr-2" width={180} />                        
                    </div> */}
                </div>
                <div className="pt-8">
                    <div className="flex flex-wrap">
                        {/* <ul>
                            <li><Link to="">My Account</Link></li>
                            <li><Link to="">Profile</Link></li>
                            <li><Link to="">Favorites</Link></li>
                            <li><Link to="">Settings</Link></li>
                        </ul> */}
                        {/* <ul>
                            <li><Link to="">Company</Link></li>
                            <li><Link to="">About</Link></li>
                            <li><Link to="">News</Link></li>
                            <li><Link to="">Terms & Privacy</Link></li>
                            <li><Link to="">Blog</Link></li>
                        </ul> */}
                        <ul>
                            <li><Link to="">Quick Links</Link></li>
                            <li><Link to="/comingsoon">Tracer3.0</Link></li>
                            <li><Link to="/comingsoon">Connect3.0</Link></li>
                            <li><Link to="/services">Web3.0 Services</Link></li>
                            {/* <li><Link to="">Web3</Link></li>
                            <li><Link to="">Gaming</Link></li> */}
                            {/* <li><Link to="">Pay</Link></li> */}
                        </ul>
                        {/* <ul>
                            <li><Link to="">Service</Link></li>
                            <li><Link to="">Referral</Link></li>
                            <li><Link to="">OTC Trading</Link></li>
                            <li><Link to="">Historical Market Data</Link></li>
                            <li><Link to="">Proof of Reserves</Link></li>
                            <li><Link to="">APIs</Link></li>
                        </ul> */}
                        <ul>
                            <li><Link to="">Support</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                        </ul>            
                    </div>
                </div>
            </div>
            {/* <div className="bg-[#2B3139] h-[1px] w-[70%] mx-auto mt-12 mb-6"></div> */}
            <div className="text-center py-10">
                Gritty Blockways Technology Services FZE© 2024
            </div>
        </div>
    )
}