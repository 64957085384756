import React from "react";
import { Link } from "react-router-dom";

const data = [
    {
        title: "Security",
        description: "Trace your lost Cryptocurrency",
        url: "/",
        action: "Coming soon"
    },
    {
        title: "Wallet",
        description: "Secure Gamer Wallet",
        url: "/comingsoon",
        action: "Coming soon"
    },
    {
        title: "Game Store",
        description: "Web3 ready plug 'n' play Game store",
        url: "/comingsoon",
        action: "Coming soon"
    },
]

export default function GetStarted() {
    return (
        <div className="text-center mt-36">
            <h2 className="text-[48px] text-purple">Get started with Gritty Blockways</h2>
            <p className="text-[23px] text-charcoal">Leverage your Business with Our end-to-end Web3.0 solutions</p>
            <div className="flex justify-center">
                <div className="flex my-12 md:my-34 mx-4 md:mx-[56px] flex-wrap lg:flex-nowrap">
                    {data.map(item => (
                        <div key={item.title} className="text-center card-background m-2 mx-8 rounded-lg mt-20">
                            <p className="mx-12 mt-12 text-[16px]">{item.title}</p>
                            <h4 className="mx-20 mt-6 text-[28px] min-h-20 max-w-[300px]">{item.description}</h4>
                            <button className="my-12 connect-wallet-btn">
                                <Link to={item.url} className="flex">
                                    {item.action}
                                </Link>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}