import React from "react";
import { Link } from "react-router-dom";

export default function Web3Games() {
    return (
        <div className="text-center pt-12 mx-4 md:mx-[56px] charcoal-text">
            <h2 className="text-[48px] text-purple">Connect3.0</h2>
            <p className="text-[23px] text-charcoal">Integrate Blockchain into games </p>
            <div className="flex justify-between mt-8">
                <div className="max-w-[500px] text-left m-20">
                    <h2 className="text-[40px] mb-8 text-purple">Unity3d</h2>
                    <p className="text-[19px] text-charcoal">Bring your games built with Unity3d Game Engine to Blockchain using our powerful connectors. Integrate games to utilize configurable game store, gamer wallet and more features.</p>
                    <button className="my-12 connect-wallet-btn">
                        <Link to="/" className="flex">
                            Available Soon..
                            {/* Find out more */}
                            <img src="/img/vector.svg" alt="arrow"/>
                        </Link>
                    </button>
                </div>
                <div>
                    <img src="img/unity.png" alt="unity"/>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                <div>
                    <img src="img/unreal.png" alt="unreal"/>
                </div>
                <div className="max-w-[500px] text-left m-20">
                    <h2 className="text-[40px] mb-8 text-purple">Unreal Engine</h2>
                    <p className="text-[19px] text-charcoal">Bring your games built with Unreal Game Engine to Blockchain using our powerful connectors. We are one of the first to launch Blockchain integration with games built using Unreal Engine.</p>
                    <button className="my-12 connect-wallet-btn">
                        <Link to="/" className="flex">
                            Available Soon..
                            {/* Find out more */}
                            <img src="/img/vector.svg" alt="arrow"/>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}